import React, { useState, useEffect } from "react";
import log from "../../Assests/images/Up_Restro_.png";
import {
  server_post_data,
  get_all_data_pos,
  save_pos_from_local,
} from "../../ServiceConnection/serviceconnection.js";
import {
  handleError,
  check_vaild_save,
  combiled_form_data,
} from "../../CommonJquery/CommonJquery.js";
import { retrieveData } from "../../LocalConnection/LocalConnection.js";
import { useNavigate } from "react-router-dom";
import {
  initIndexedDB,
  storeDataInObjectStore,
  retrieveDataFromObjectStore,
  array_data_base,
  category_table_name,
  menu_table_name,
  other_charges_table_name,
  qr_code_table_name,
  rupee_icon_table_name,
  save_pos_temp_cart_icon_table_name,
  storetax_data,
  submit_data_into_local,
  customer_order_data_name,
  customer_order_data_details,
  customer_user_data,
} from "../../LocalConnection/OfflineConnection.js";
const HomePos = () => {
  /**shubham jain new code */
  const navigate = useNavigate();
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [CategoryData, setsCategoryData] = useState([]);
  const [ResturantData, setResturantData] = useState([]);
  const [MenuData, setMenuData] = useState([]);
  const [QRcodeData, setQRcodeData] = useState([]);
  const [StoreData, setStoreData] = useState([]);
  const [CustomerUserData, setCustomerUserData] = useState([]);
  const [orderType, setOrderType] = useState(1);
  const [RupessData, setRupessData] = useState("₹");
  const [activeCategory, setActiveCategory] = useState(null);
  const [StoreTaxData, setStoreTaxDataa] = useState([]);
  const retrievedAdminId = retrieveData("staff_id");
  const [db, setDb] = useState(null);

  useEffect(() => {
    const isOnline = navigator.onLine;

    const setDbCallback = async (result) => {
      setDb(result);

      if (isOnline) {
        const start_date = "";

        const end_date = "";
        const flag = "1";
        const call_id = "0";
        master_data_get(start_date, end_date, flag, call_id, result);
      } else {
        const menuData = await retrieveDataFromObjectStore(
          result,
          menu_table_name,
          ""
        );

        const categoryData = await retrieveDataFromObjectStore(
          result,
          category_table_name,
          ""
        );
        const other_charges = await retrieveDataFromObjectStore(
          result,
          other_charges_table_name,
          ""
        );
        const rupee_icon = await retrieveDataFromObjectStore(
          result,
          rupee_icon_table_name,
          ""
        );

        const qr_code = await retrieveDataFromObjectStore(
          result,
          qr_code_table_name,
          ""
        );
        const customer_user_data_full = await retrieveDataFromObjectStore(
          result,
          customer_user_data,
          ""
        );
       

        setsCategoryData(categoryData);
        setMenuData(menuData);
        setResturantData(other_charges[0]);
        setQRcodeData(qr_code);

        setRupessData(rupee_icon[0].rupees_icon);
        setCustomerUserData(customer_user_data_full);
        setActiveCategory(categoryData[0].cu_id);
      }
    };

    // Call the initIndexedDB function and pass the callback
    initIndexedDB(setDbCallback);
  }, []);

  const master_data_get = async (
    start_date,
    end_date,
    flag,
    call_id,
    result
  ) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", retrievedAdminId);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_data_pos, fd)
      .then(async (Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          const transaction_call = result.transaction(
            array_data_base,
            "readwrite"
          );
          setsCategoryData(Response.data.message.get_category);
          setMenuData(Response.data.message.get_menu);
          setResturantData(Response.data.message.other_charges[0]);
          setQRcodeData(Response.data.message.qr_code);
          setRupessData(Response.data.message.rupees);
          setActiveCategory(Response.data.message.get_category[0].cu_id);
          setCustomerUserData(Response.data.message.customer_user_data);
          // Storing data in separate object stores
          storeDataInObjectStore(
            transaction_call,
            category_table_name,
            Response.data.message.get_category,
            "clear",
            "0",
            ""
          );
          storeDataInObjectStore(
            transaction_call,
            menu_table_name,
            Response.data.message.get_menu,
            "clear",
            "0",
            ""
          );
          storeDataInObjectStore(
            transaction_call,
            other_charges_table_name,
            Response.data.message.other_charges[0],
            "clear",
            "1",
            ""
          );
          storeDataInObjectStore(
            transaction_call,
            qr_code_table_name,
            Response.data.message.qr_code,
            "clear",
            "0",
            ""
          );
          storeDataInObjectStore(
            transaction_call,
            rupee_icon_table_name,
            Response.data.message.rupees,
            "clear",
            "4",
            ""
          );

          storeDataInObjectStore(transaction_call, customer_user_data, [
            Response.data.message.customer_user_data,
            "clear",
            "0",
            "",
          ]);

          transaction_call.oncomplete = () => {
            console.log("Transaction completed successfully");
          };
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.error("Error in server_post_data:", error);
        setshowLoaderAdmin(false);
      });
  };

  const handleOrderTypeChange = (e) => {
    setOrderType(parseInt(e.target.value, 10));
  };

  const handleAddToCart = async (full_data, plus_min) => {
    // Your add to cart logic here
    const transaction_call = db.transaction(array_data_base, "readwrite");
    storeDataInObjectStore(
      transaction_call,
      save_pos_temp_cart_icon_table_name,
      full_data,
      "not",
      "2",
      plus_min
    );

    const save_pos_temp_cart = await retrieveDataFromObjectStore(
      db,
      save_pos_temp_cart_icon_table_name,
      ""
    );
    setStoreData(save_pos_temp_cart);

    change_calculation("4", save_pos_temp_cart);
  };

  const change_calculation = (click_type, e) => {
    if (click_type !== "4") {
      if (e.target.value !== "") {
        e.target.value = e.target.value.replace(/[^0-9.]/g, ""); // Allow only digits and decimal point
        e.target.value = e.target.value.replace(
          /(\d{0,9}(?:\.\d{0,2})?).*$/g,
          "$1"
        ); // Limit to 3 digits before the decimal and 2 digits after
      } else {
        e.target.value = 0;
      }
    }

    let packing_mount_get = document.getElementById(
      "extra_packing_amount"
    ).value;
    let delivery_mount_get = document.getElementById(
      "extra_delivery_amount"
    ).value;
    let discount_mount_get = document.getElementById("promocode_amount").value;
    let StoreDatat_get = StoreData;
    let tax_amount_get = ResturantData.taxes_charges;
    if (click_type === "1") {
      packing_mount_get = e.target.value;
    } else if (click_type === "2") {
      delivery_mount_get = e.target.value;
    } else if (click_type === "3") {
      discount_mount_get = e.target.value;
    } else if (click_type === "4") {
      StoreDatat_get = e;
    }

    const save_pos_temp_cart_database = storetax_data(
      db,
      StoreDatat_get,
      packing_mount_get,
      delivery_mount_get,
      discount_mount_get,
      tax_amount_get
    );

    if (click_type === "3") {
      // Find the 'Discount Price' in StoreTaxData and update inputValue
      save_pos_temp_cart_database.map((cake) => {
        if (cake.cart_name === "Discount Price") {
          e.target.value = cake.amount_show;
        }
      });
    }

    setStoreTaxDataa(save_pos_temp_cart_database);
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    let mobile_no = document.getElementById("mobile").value;
    let customer_name = document.getElementById("customer_name").value;
    let customer_email = document.getElementById("customer_email").value;
    let pickuptime = document.getElementById("pickuptime").value;
    let address_user = document.getElementById("address_user").value;
    let table_no = document.getElementById("table_no").value;
    const orderTypeRadios = document.getElementsByName("order_type");

    // Loop through the radio buttons to find the selected one
    let selectedOrderType;
    for (const radio of orderTypeRadios) {
      if (radio.checked) {
        selectedOrderType = radio.value;
        break; // Stop the loop when the selected radio is found
      }
    }

    if (vaild_data) {
      submit_data_into_local(
        db,
        StoreTaxData,
        RupessData,
        mobile_no,
        customer_name,
        customer_email,
        pickuptime,
        address_user,
        selectedOrderType,
        table_no
      );
      const customer_order_data = await retrieveDataFromObjectStore(
        db,
        customer_order_data_name,
        ""
      );
      const customer_order_details = await retrieveDataFromObjectStore(
        db,
        customer_order_data_details,
        ""
      );

      // Get the last object in the array
      const lastOrder = customer_order_data[customer_order_data.length - 1];
      // Get the 'token' property from the last object
      const lastToken = lastOrder.token;

      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);

      fd_from.append("lead_taken_by_staff", retrievedAdminId);
      fd_from.append(
        "customer_order_data",
        JSON.stringify(customer_order_data)
      );
      fd_from.append(
        "customer_order_data_details",
        JSON.stringify(customer_order_details)
      );

      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            console.log(Response.data.message);
            const transaction_call = db.transaction(
              array_data_base,
              "readwrite"
            );

            storeDataInObjectStore(
              transaction_call,
              customer_order_data_name,
              [],
              "clear",
              "non",
              ""
            );
            storeDataInObjectStore(
              transaction_call,
              customer_order_data_details,
              [],
              "clear",
              "non",
              ""
            );
            navigate("/invoice/" + Response.data.c_order_id + "/online");
            // handleSuccessSession(Response.data.message, "/");
          }
        })
        .catch((error) => {
          console.log(error);
          setshowLoaderAdmin(false);
          navigate("/invoice/" + lastToken + "/offline");
        });
    }
  };
  /**shubham jain new code */

  return (
    <div>
      <>
        <div className="container-fluid main-content">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <a className="navbar-brand" href="/">
              <img src={log} alt="logo" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="rest_name ml-5 pl-5">
              <p className="text-center pl-5 ml-5">
                {ResturantData.restaurant_name}
              </p>
            </div>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item ">
                  <a href="/" style={{ backgroundColor: "transparent" }}>
                    <button className="btn btn-success">Home</button>
                  </a>
                </li>
                &nbsp;&nbsp;&nbsp;
                <li className="nav-item ">
                  <a
                    href="tel:+919425653634"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <button className="btn btn-danger">
                      <i className="fa fa-phone" /> +919425653634
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <section className="main_contant">
          <div className="row">
            <div className="col-lg-2 col-md-2 col-12 d-none d-sm-none d-md-block d-lg-block l-xl-block">
              <div
                className="nav-side-menu height_new"
                style={{ marginTop: "-50px" }}
              >
                <div className="brand">Menu</div>
                <div className="menu-list scrollbar">
                  <div className="sidebar">
                    <ul id="menu-content" className="menu-content ">
                      {CategoryData.map((blogddd, index) => (
                        <li
                          data_id={blogddd.cu_id}
                          className={
                            activeCategory === blogddd.cu_id
                              ? "active"
                              : "deactive_class"
                          }
                          onClick={(e) => setActiveCategory(blogddd.cu_id)}
                          key={index}
                        >
                          {" "}
                          {blogddd.category_name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-12 food_item my-0 height_new">
              <div>
                {MenuData.map((cake, index) => (
                  <button
                    key={index}
                    className={
                      activeCategory === cake.cate_id
                        ? " btn btn-success col-md-2 col-3"
                        : "all_hide btn btn-success col-md-2 col-3"
                    }
                    onClick={() => handleAddToCart(cake, "plus")}
                  >
                    {cake.menu_name}
                    <br /> {RupessData} {cake.menu_price}
                  </button>
                ))}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12 my-0 height_new">
              <form className="needs-validation" id="CarrerformData">
                <div className="order2">
                  <h6 style={{ padding: "8px 0px 0px 10px" }}>
                    Customer Details
                    <input
                      type="radio"
                      id="takeaway"
                      name="order_type"
                      value={1}
                      checked={orderType === 1}
                      onChange={handleOrderTypeChange}
                    />
                    <label htmlFor="html">Takeaway</label>&nbsp;
                    <input
                      type="radio"
                      id="delivery"
                      name="order_type"
                      value={2}
                      checked={orderType === 2}
                      onChange={handleOrderTypeChange}
                    />
                    <label htmlFor="css">Delivery</label>&nbsp;
                    <input
                      type="radio"
                      id="qr_code"
                      name="order_type"
                      value={0}
                      checked={orderType === 0}
                      onChange={handleOrderTypeChange}
                    />
                    <label htmlFor="javascript">Dine-in</label>
                  </h6>
                  <div className="row" style={{ margin: "0px 5px 0px 0px" }}>
                    <div
                      className={`col-md-12  ${
                        orderType === 0 ? "" : "hidden"
                      }`}
                    >
                      <div
                        className="form-group"
                        style={{ padding: "0% !important" }}
                      >
                        <select
                          className="chosen-select form-control mylist ajax_save "
                          name="table_no"
                          id="table_no"
                          data-placeholder="Choose a Outlet..."
                        >
                          <option value={"0~@~ "}>No Table</option>
                          {QRcodeData.map((blogddd, index) => (
                            <option
                              value={blogddd.qr_id + "~@~" + blogddd.table_code}
                              key={index}
                            >
                              {" "}
                              {blogddd.table_code}
                            </option>
                          ))}
                        </select>
                        <span className="condition_error"></span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div
                        className="form-group "
                        style={{ padding: "0% !important" }}
                      >
                        <input
                          type="text"
                          maxLength={10}
                          className="form-control ajax_save check_moblie_no"
                          defaultValue=""
                          placeholder="Mobile Number"
                          name="mobile"
                          id="mobile"
                        />
                        <span className="condition_error"></span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div
                        className="form-group "
                        style={{ padding: "0% !important" }}
                      >
                        <input
                          type="text"
                          className="form-control ajax_save "
                          placeholder="Name"
                          name="customer_name"
                          defaultValue=" "
                          id="customer_name"
                        />
                        <span className="condition_error"></span>
                      </div>
                    </div>
                    <div className="col-md-12 hidden">
                      <div
                        className="form-group "
                        style={{ padding: "0% !important" }}
                      >
                        <input
                          type="text"
                          className="form-control ajax_save "
                          placeholder="Email"
                          name="customer_email"
                          defaultValue=" "
                          id="customer_email"
                        />
                        <span className="condition_error"></span>
                      </div>
                    </div>
                    <div
                      className={`col-md-12  ${
                        orderType === 1 ? "" : "hidden"
                      }`}
                    >
                      <div
                        className="form-group "
                        style={{ padding: "0% !important" }}
                      >
                        <input
                          type="time"
                          defaultValue="11:29"
                          className="form-control ajax_save "
                          placeholder="Enter Time"
                          name="pickuptime"
                          id="pickuptime"
                        />
                        <span className="condition_error"></span>
                      </div>
                    </div>
                    <div
                      className={`col-md-12  ${
                        orderType === 2 ? "" : "hidden"
                      }`}
                    >
                      <div
                        className="form-group "
                        style={{ padding: "0% !important" }}
                      >
                        <textarea
                          id="address_user"
                          name="address_user"
                          rows={4}
                          style={{ width: "100%" }}
                          placeholder="Address here..."
                          defaultValue={""}
                        />
                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <table className="table ">
                  <tbody>
                    <tr className="table_header mt-4">
                      <td>Itme</td>
                      <td align="center" style={{ paddingLeft: 70 }}>
                        Quantity
                      </td>
                      <td align="right" style={{ paddingRight: 50 }}>
                        Price
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  className="order"
                  style={{ height: 150, overflow: "auto" }}
                >
                  <table
                    className="table show_table"
                    style={{ overflow: "auto", maxHeight: 100 }}
                  >
                    {StoreData.map((cake, index) => {
                      if (cake.cart_value > 0) {
                        return (
                          <tr key={index}>
                            <td>
                              <i
                                className="fa fa-times-circle"
                                aria-hidden="true"
                              ></i>{" "}
                              {cake.menu_name}
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-danger1 btn-number input_table"
                                onClick={() => handleAddToCart(cake, "min")}
                              >
                                -
                              </button>
                              <input
                                type="text"
                                className="input-number input_table"
                                value={cake.cart_value}
                                min="1"
                                max="20"
                                readOnly
                              />
                              <button
                                type="button"
                                className="btn btn-success1 btn-number"
                                onClick={() => handleAddToCart(cake, "plus")}
                              >
                                +
                              </button>
                            </td>
                            <td className="priceTitle">
                              {RupessData} {cake.total_price_show}
                            </td>
                          </tr>
                        );
                      }

                      return null; // if cart_value is not greater than 0, return null or an empty fragment
                    })}
                  </table>
                </div>
                <div className="order">
                  <table className="table bottom_table">
                    <tbody>
                      {StoreTaxData &&
                        StoreTaxData.filter(
                          (cake) => Number(cake.amount_show) > 0
                        ).map((cake, index) => (
                          <tr className="order_bottom" key={index}>
                            <td
                              align="left"
                              style={{
                                paddingLeft: 30,
                                fontSize: 13,
                                fontWeight: 600,
                              }}
                            >
                              {cake.cart_name}
                            </td>
                            <td
                              colSpan={2}
                              align="right"
                              style={{
                                paddingRight: 50,
                                fontSize: 13,
                                fontWeight: 600,
                              }}
                            >
                              {RupessData} {cake.amount_show}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div
                  className="order_bottom_button pl-3 pt-0 "
                  style={{ paddingTop: "0px!important" }}
                >
                  <div className="row">
                    <div
                      className={`col-md-4  ${
                        orderType === 1 || orderType === 2 ? "" : "hidden"
                      }`}
                    >
                      <h5 className="new_font">Packing Amount</h5>
                      <div
                        className="form-group"
                        style={{ padding: "0% !important" }}
                      >
                        <input
                          type="text"
                          maxLength={10}
                          className="form-control trio_mandatory"
                          defaultValue="0"
                          onInput={(e) => change_calculation("1", e)}
                          placeholder="Enter Packing Amount"
                          name="extra_packing_amount"
                          id="extra_packing_amount"
                        />
                      </div>
                    </div>
                    <div
                      className={`col-md-4  ${orderType === 2 ? "" : "hidden"}`}
                    >
                      <h5 className="new_font">Delivery Amount</h5>
                      <div
                        className="form-group"
                        style={{ padding: "0% !important" }}
                      >
                        <input
                          type="text"
                          maxLength={10}
                          className="form-control trio_mandatory"
                          placeholder="Enter Delivery Amount"
                          defaultValue="0"
                          onInput={(e) => change_calculation("2", e)}
                          name="extra_delivery_amount"
                          id="extra_delivery_amount"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <h5 className="new_font">Discount Amount</h5>
                      <div
                        className="form-group"
                        style={{ padding: "0% !important" }}
                      >
                        <input
                          type="text"
                          maxLength={10}
                          className="form-control trio_mandatory "
                          defaultValue="0"
                          onInput={(e) => change_calculation("3", e)}
                          placeholder="Enter Discount Amount"
                          name="promocode_amount"
                          id="promocode_amount"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {StoreData && StoreData.length > 0 && (
                  <div
                    className="order_bottom_button pl-3 pt-0"
                    style={{ paddingBottom: 15 }}
                  >
                    <div className="row">
                      <div className="col-6 " style={{ marginTop: 10 }}>
                        <input
                          type="radio"
                          name="payment_type"
                          id="cash"
                          defaultValue="cash"
                          defaultChecked=""
                        />{" "}
                        Cash
                        <input
                          type="radio"
                          name="payment_type"
                          defaultValue="online"
                          id="online"
                        />{" "}
                        Online
                      </div>
                      <div className="col-6 ">
                        <button
                          type="button"
                          style={{ float: "right", marginRight: 15 }}
                          onClick={() =>
                            handleSaveChangesdynamic(
                              "CarrerformData",
                              save_pos_from_local
                            )
                          }
                          className="btn btn1"
                          id="save_button"
                        >
                          Save &amp; Print
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </section>
      </>
    </div>
  );
};

export default HomePos;
