import axios from "axios";

const appauth_key = "Rahuwanshi@2029";
let data_base_name = "rishibakery";
let APL_LINK = "http://192.168.1.17:8000";
APL_LINK = "https://" + data_base_name + ".uprestro.com/";
let Website_URL = "https://api.raghuvanshihall.com/";
let local_server_link_pos = APL_LINK + "/OfflinePos/";

/**POS panel */
const get_all_data_pos = local_server_link_pos + "pos_api_call";
const save_pos_from_local = local_server_link_pos + "save_pos_from_local";
const get_invoice_data_single =
  local_server_link_pos + "get_invoice_data_single";
/**POS panel */
// Retrieving data
const server_post_data = async (url_for, form_data) => {
  if (form_data === null) {
    form_data = new FormData();
  }

  form_data.append("appauth_key", appauth_key);
  form_data.append("database_name_name", data_base_name);
  return axios.post(url_for, form_data);
};

export {
  APL_LINK,
  Website_URL,
  appauth_key,
  server_post_data,

  /*POS api */
  get_all_data_pos,
  save_pos_from_local,
  get_invoice_data_single,
  /**POS  panel */
};
