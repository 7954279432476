import { uid } from "uid";
import { computeTodayDate } from "../CommonJquery/CommonJquery.js";
const category_table_name = "category";
const menu_table_name = "menu";
const other_charges_table_name = "other_charges";
const qr_code_table_name = "qr_code";
const rupee_icon_table_name = "rupee_icon";
const save_pos_temp_cart_icon_table_name = "save_pos_temp_cart";
const tax_save_cart_table_name = "tax_save_cart";
const customer_user_data = "customer_user_data";
const customer_order_data_name = "customer_order_data";
const customer_order_data_details = "customer_order_data_details";

const array_data_base = [
  category_table_name,
  menu_table_name,
  other_charges_table_name,
  qr_code_table_name,
  rupee_icon_table_name,
  save_pos_temp_cart_icon_table_name,
  tax_save_cart_table_name,
  customer_order_data_name,
  customer_order_data_details,
  customer_user_data,
];

const initIndexedDB = async (setDbCallback) => {
  const request = indexedDB.open("OfflineFormDataDB", 105);
  let database;

  request.onupgradeneeded = (event) => {
    database = event.target.result;
    for (const storeName of array_data_base) {
      createObjectStore_DATA(storeName, database);
    }
  };

  request.onsuccess = (event) => {
    setDbCallback(event.target.result);
  };

  request.onerror = (event) => {
    console.error("Error opening IndexedDB:", event.target.error);
  };
};

const createObjectStore_DATA = (databse_name, database_object) => {
  if (!database_object.objectStoreNames.contains(databse_name)) {
    database_object.createObjectStore(databse_name, {
      keyPath: "id",
      autoIncrement: true,
    });
  }
};

const storeDataInObjectStore = (
  transaction,
  storeName,
  data,
  clear_or_not,
  single_mutiple,
  plus_min = ""
) => {
  const store = transaction.objectStore(storeName);
  if (clear_or_not === "clear") {
    store.clear();
  }
  console.log(single_mutiple);
  // Assuming data is an array of data items
  if (single_mutiple === "0") {
    data.forEach((dataItem) => {
      const modifiedDataItem = {
        ...dataItem,
        data_type: "online",
      };
      store.add(modifiedDataItem);
    });
  } else if (single_mutiple === "1") {
    const modifiedDataItem = {
      ...data,
      data_type: "online",
    };
    console.log(modifiedDataItem);
    store.add(modifiedDataItem);
  } else if (single_mutiple === "4") {
    const modifiedDataItem = {
      rupees_icon: data,
    };
    store.add(modifiedDataItem);
  } else if (single_mutiple === "2") {
    const { menu_id, cate_id } = data;
    let foundRecord = null;
    const request = store.getAll();

    request.onsuccess = (event) => {
      const records = event.target.result;
      console.log(records);
      records.forEach((cake) => {
        if (
          cake.menu_id === menu_id &&
          cake.cate_id === cate_id &&
          !foundRecord
        ) {
          foundRecord = cake;
        }
      });
      if (foundRecord !== null) {
        // If record exists, update cart_value

        let plus_count = 0;
        if (plus_min === "plus") {
          plus_count = foundRecord.cart_value + 1;
        } else {
          plus_count = foundRecord.cart_value - 1;
        }
        let total_price = plus_count * foundRecord.menu_price;

        if (plus_count >= 0) {
          const updatedDataItem = {
            ...foundRecord,
            cart_value: plus_count, // Increment cart_value by 1
            total_price_show: parseFloat(total_price).toFixed(2), // Increment cart_value by 1
            data_type: "online",
          };
          store.put(updatedDataItem);
        }
      } else {
        // If record doesn't exist, add it with cart_value set to 1
        const modifiedDataItem = {
          ...data,
          cart_value: 1,
          total_price_show: parseFloat(data.menu_price).toFixed(2), // Increment cart_value by 1
          data_type: "online",
        };

        store.add(modifiedDataItem);
      }
    };
  }
};

const retrieveDataFromObjectStore = (db, storeName, filter_by_id = "") => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, "readonly");
    const store = transaction.objectStore(storeName);
    let request;
    if (filter_by_id === "") {
      request = store.getAll();
    } else {
      request = store.getAll();
    }
    transaction.oncomplete = () => {
      resolve(request.result);
    };
    transaction.onerror = (event) => {
      reject(
        `Error retrieving data from ${storeName} object store: ${event.target.error}`
      );
    };
  });
};

const storetax_data = (
  db,
  save_pos_temp_cart_loop,
  packing_mount,
  delivery_mount,
  discount_mount,
  tax_amount
) => {
  // Start a new transaction
  const transaction = db.transaction(tax_save_cart_table_name, "readwrite");

  // Access the object store within the transaction
  const store = transaction.objectStore(tax_save_cart_table_name);
  store.clear();
  let Product_Price = 0;
  let Discount_amount = discount_mount;
  let Taxes_amount = 0;
  let Packing_Charge = packing_mount;
  let Delivery_Charge = delivery_mount;
  let Final_Price = 0;
  save_pos_temp_cart_loop.forEach((dataItem) => {
    Product_Price += Number(dataItem.total_price_show);
  });

  if (Number(tax_amount) > 0 && Number(Product_Price) > 0) {
    Taxes_amount = (Number(tax_amount) / 100) * Number(Product_Price);
  }

  if (Product_Price < Discount_amount) {
    Discount_amount = 0;
  }

  Final_Price =
    Number(Product_Price) -
    Number(Discount_amount) +
    Number(Taxes_amount) +
    Number(Packing_Charge) +
    Number(Delivery_Charge);

  const itemsToStore = [
    {
      cart_name: "Product Price",
      amount_show: parseFloat(Product_Price).toFixed(2),
    },
    {
      cart_name: "Discount Price",
      amount_show: parseFloat(Discount_amount).toFixed(2),
    },
    {
      cart_name: "Taxes Price",
      amount_show: parseFloat(Taxes_amount).toFixed(2),
    },
    {
      cart_name: "Packing Price",
      amount_show: parseFloat(Packing_Charge).toFixed(2),
    },
    {
      cart_name: "Delivery Price",
      amount_show: parseFloat(Delivery_Charge).toFixed(2),
    },
    {
      cart_name: "Final Price",
      amount_show: parseFloat(Final_Price).toFixed(2),
    },
  ];

  itemsToStore.forEach((item) => {
    store.add(item);
  });

  return itemsToStore;
};

const submit_data_into_local = (
  db,
  StoreTaxData,
  rupees_icon,
  mobile_no,
  customer_name,
  customer_email,
  pickuptime,
  address_user,
  selectedOrderType,
  table_no
) => {
  const transaction = db.transaction(
    [
      customer_order_data_name,
      customer_order_data_details,
      save_pos_temp_cart_icon_table_name,
    ],
    "readwrite"
  );

  // Access the object stores within the transaction
  const store = transaction.objectStore(customer_order_data_name);
  const store_details = transaction.objectStore(customer_order_data_details);
  const store_temp_cart = transaction.objectStore(
    save_pos_temp_cart_icon_table_name
  );

  const table_no_id_id = table_no.split("~@~");

  let token_uuid = uid(25);
  let pickup_time_id = pickuptime;
  let table_id_id = table_no_id_id[0];
  let table_no_id = table_no_id_id[1];
  let moblie_no_id = mobile_no;
  let customer_name_id = customer_name;
  let customer_email_id = customer_email;
  let promo_code_id = "";
  let address_users_id = address_user;
  let est_amount_id = "0.00";
  let tax_amount_id = "0.00";
  let packing_amount_id = "0.00";
  let promocode_amount_id = "0.00";
  let amountpay_id = "0.00";
  let wallet_amount_id = "0.00";
  let delivery_amount_id = "0.00";
  let tip_amount_id = "0.00";

  let order_type_id = selectedOrderType; //0 means order food qr code,1 means takeaway,2 means delivery

  StoreTaxData.forEach((item) => {
    console.log(item);

    if (item.cart_name === "Product Price") {
      est_amount_id = item.amount_show;
    }
    if (item.cart_name === "Discount Price") {
      promocode_amount_id = item.amount_show;
    }
    if (item.cart_name === "Packing Price") {
      packing_amount_id = item.amount_show;
    }
    if (item.cart_name === "Delivery Price") {
      delivery_amount_id = item.amount_show;
    }
    if (item.cart_name === "Taxes Price") {
      tax_amount_id = item.amount_show;
    }
    if (item.cart_name === "Final Price") {
      amountpay_id = item.amount_show;
    }
  });

  const modifiedDataItem = {
    cd_id: 0,
    customer_name: customer_name_id,
    customer_email: customer_email_id,
    invoice_id: 0,
    address_id: 0,
    res_id: 1,
    pro_id: 0,
    pickup_date: computeTodayDate(),
    pickup_time: pickup_time_id,
    table_id: table_id_id,
    table_no: table_no_id,
    moblie_no: moblie_no_id,
    address_users: address_users_id,
    promo_code: promo_code_id,
    est_amount: est_amount_id,
    tax_amount: tax_amount_id,
    packing_amount: packing_amount_id,
    promocode_amount: promocode_amount_id,
    wallet_amount: wallet_amount_id,
    delivery_amount: delivery_amount_id,
    tip_amount: tip_amount_id,
    amountpay: amountpay_id,
    send_sms: 0,
    current_status: 0,
    order_type: order_type_id,
    special_instruction: "",
    token: token_uuid,
    data_type: "offline",
  };

  store.add(modifiedDataItem);

  const request = store_temp_cart.getAll();

  request.onsuccess = (event) => {
    const records = event.target.result;
    records.forEach((cake) => {
      const modifiedDataItem = {
        item_id: cake.menu_id,
        item_name: cake.menu_name,
        item_icon: rupees_icon,
        item_qty: cake.cart_value,
        item_price: cake.menu_price,
        total_price: cake.total_price_show,
        token: token_uuid,
        data_type: "offline",
      };

      store_details.add(modifiedDataItem);
    });
  };
  store_temp_cart.clear();
};

export {
  initIndexedDB,
  storeDataInObjectStore,
  retrieveDataFromObjectStore,
  storetax_data,
  array_data_base,
  category_table_name,
  menu_table_name,
  other_charges_table_name,
  qr_code_table_name,
  rupee_icon_table_name,
  save_pos_temp_cart_icon_table_name,
  tax_save_cart_table_name,
  submit_data_into_local,
  customer_order_data_name,
  customer_order_data_details,
  customer_user_data,
};
