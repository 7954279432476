import "./Css/style.css";
import "./Css/bootstyle.css";
import "./Css/invoice.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePos from "./Components/POS/HomePos";
import InvoicePos from "./Components/POS/InvoicePos";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePos />} />
          <Route path="/invoice/:id/:status" element={<InvoicePos />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
